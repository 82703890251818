// Couleurs principales
$blue1: #199fe2;
$blue2: #102c3a;
$blue3: #146187;
$blue4: #11384b;
$blue5: #045f7d;
$blue6: #035a77;
$blue7: hsl(199, 100%, 25%);
$blue8: #13a3dc;
$blue_header: #0c81ae;
$orange: #ff7200;
$orange2: #ff5a00;
$orange3: #fda804;
$green1: #01a59b;
$green2: #ccedeb;
$green3: #007a73;
$grey1: #424242;
$grey2: #a8a8a8;
$grey3: #f1f2f4;
$grey4: #f8f8f8;
$rose1: #ff3366;
$red1: #e60000;
// Types de contenus
$cpro: #ff5a00;
$module: #ea63d2;
$sequence: #ab80fb;
$ressource: #199fe2;
$miseappli: #19bae2;
$eval: #007a73;
$corrige: #1f94ce;
$tp: #01a59b;
